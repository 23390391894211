import * as React from "react"

import * as styles from "./centralVideoForInformation.module.css"

const CentralVideoForInformation = () => {

  return (
    <section className={styles.section}>
      <div className={styles.bufferUp}></div>
      <div className={styles.flexColumn}>
        <div className={styles.top}>
        </div>
        <div className={styles.main}>
          <div className={styles.video}>
            <iframe
              title="Timelapse of moving shadows in a condo | Timelapse d'ombres qui bougent dans un condo"
              src="https://player.vimeo.com/video/722307003?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              allowfullscreen style={{ border: "0", overflow: "hidden" }}></iframe>
          </div>
        </div>
        <div className={styles.bottom}>
        </div>
      </div>
      <div className={styles.bufferUp}></div>
    </section>
  )
}

export default CentralVideoForInformation
