// extracted by mini-css-extract-plugin
export var imgTop = "sectionTextAndVideo-module--imgTop--CZ4VB";
export var left = "sectionTextAndVideo-module--left--oSeWo";
export var mainRow = "sectionTextAndVideo-module--mainRow--70CAc";
export var mid = "sectionTextAndVideo-module--mid--HofQN";
export var right = "sectionTextAndVideo-module--right--WX6OK";
export var rightColumn = "sectionTextAndVideo-module--rightColumn--YGho6";
export var section = "sectionTextAndVideo-module--section--9scXg";
export var sep = "sectionTextAndVideo-module--sep--gxaCn";
export var top = "sectionTextAndVideo-module--top--xCzLY";
export var video = "sectionTextAndVideo-module--video--XxrIa";
export var videoContainer = "sectionTextAndVideo-module--videoContainer--b4XRs";