// extracted by mini-css-extract-plugin
export var buttonLinkSep = "textWithImagesInline2-module--buttonLinkSep--HnQUs";
export var coloredSpan = "textWithImagesInline2-module--coloredSpan--JB2BF";
export var flexColumn = "textWithImagesInline2-module--flexColumn--B6Rst";
export var hoverZone = "textWithImagesInline2-module--hoverZone--rdsNs";
export var hovered = "textWithImagesInline2-module--hovered--8qVTy";
export var link = "textWithImagesInline2-module--link--OiJnu";
export var main = "textWithImagesInline2-module--main--sqzUJ";
export var paragraph = "textWithImagesInline2-module--paragraph--48kuq";
export var section = "textWithImagesInline2-module--section--7l5Ue";
export var spanImg = "textWithImagesInline2-module--spanImg--7Zk6E";
export var spanWW = "textWithImagesInline2-module--spanWW--6tyR0";
export var spanWrapper = "textWithImagesInline2-module--spanWrapper--pN5Bm";
export var staticImg = "textWithImagesInline2-module--staticImg--xUpZ2";
export var title = "textWithImagesInline2-module--title--UblEp";