import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./sectionVideoAndText.module.css"
import { StaticImage } from "gatsby-plugin-image"

const SectionVideoAndText = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={`row ${styles.mainRow}`}>
        <div className={`d-none d-xl-block ${styles.sep}`}></div>
        <div className={`col-xl-6 ${styles.left}`}>
          <div className={styles.videoContainer}>
            <StaticImage
              className={styles.imgBack}
              src='../images/information/Mansfield_036A_L.jpg'
              loading="eager"
              placeholder="blurred"
              layout="constrained"
              height={1000}
              quality={90}
              formats={["auto", "webp", "avif"]}
              alt={intl.formatMessage({ id: 'alts.building' })}
            />
          </div>
        </div>
        <div className={`col-xl-6 ${styles.right}`}>
          <div className={styles.rightColumn}>
            <div className={styles.top}>
              <h4>{intl.formatMessage({ id: 'information.videoTextRight.top' })}</h4>
              <p>{intl.formatMessage({ id: 'information.videoTextRight.m1' })}</p>
            </div>
            <div className={styles.verticalSep}></div>
            <div className={styles.bottom}>
              <h4>{intl.formatMessage({ id: 'information.videoTextRight.bottom' })}</h4>
              <p>{intl.formatMessage({ id: 'information.videoTextRight.m2' })}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionVideoAndText