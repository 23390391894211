// extracted by mini-css-extract-plugin
export var bottom = "sectionVideoAndText-module--bottom--X9FfF";
export var imgBack = "sectionVideoAndText-module--imgBack--v6GQE";
export var left = "sectionVideoAndText-module--left--6eMGy";
export var mainRow = "sectionVideoAndText-module--mainRow--ScyyF";
export var right = "sectionVideoAndText-module--right--fa50+";
export var rightColumn = "sectionVideoAndText-module--rightColumn--xb4ck";
export var section = "sectionVideoAndText-module--section--FCUKv";
export var sep = "sectionVideoAndText-module--sep--aksQC";
export var top = "sectionVideoAndText-module--top--egug7";
export var verticalSep = "sectionVideoAndText-module--verticalSep---rBec";
export var video = "sectionVideoAndText-module--video--KkFuk";
export var videoContainer = "sectionVideoAndText-module--videoContainer--u8XmK";