import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import Information from "../components/pages/Information"

import { useIntl } from "gatsby-plugin-intl-v4"

const InformationPage = () => {

  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'h1.information' })}
        description={intl.formatMessage({ id: 'seo.description' })}
        pageName={'information'}
      />
      <Information />
    </Layout>
  )
}

export default InformationPage
