import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./sectionTextAndVideo.module.css"
import { StaticImage } from "gatsby-plugin-image"

const SectionTextAndVideo = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={`row ${styles.mainRow}`}>
        <div className={`d-none d-xl-block ${styles.sep}`}></div>
        <div className={`col-xl-6 ${styles.left}`}>
          <div className={styles.videoContainer}>
            <div className={styles.video}>
              <StaticImage
                src='../images/information/Mansfield_026.jpg'
                loading="eager"
                layout="constrained"
                height={1000}
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.theater' })}
                style={{
                  height: "100%",
                  width: "100%"
                }}
              />
            </div>
          </div>
        </div>
        <div className={`col-xl-6 ${styles.right} order-xl-first`}>
          <div className={styles.rightColumn}>
            <div className={styles.top}>
              <h4>
                {intl.formatMessage({ id: 'information.videoTextLeft.top' })}
                <br />
                {intl.formatMessage({ id: 'information.videoTextLeft.bottom' })}
              </h4>
            </div>
            <div className={styles.mid}>
              <p>{intl.formatMessage({ id: 'information.videoTextLeft.m1' })}</p>
              <p>{intl.formatMessage({ id: 'information.videoTextLeft.m2' })}</p>
              <p>{intl.formatMessage({ id: 'information.videoTextLeft.m3' })}</p>
              <p>{intl.formatMessage({ id: 'information.videoTextLeft.m4' })}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTextAndVideo