// extracted by mini-css-extract-plugin
export var buttonLinkSep = "textWithImagesInline1-module--buttonLinkSep--32o5n";
export var coloredSpan = "textWithImagesInline1-module--coloredSpan--CQAA0";
export var flexColumn = "textWithImagesInline1-module--flexColumn--zmUB4";
export var hoverZone = "textWithImagesInline1-module--hoverZone--mk5kL";
export var hovered = "textWithImagesInline1-module--hovered--Ant54";
export var link = "textWithImagesInline1-module--link--Sbh3X";
export var main = "textWithImagesInline1-module--main--sPRIQ";
export var paragraph = "textWithImagesInline1-module--paragraph--LNrpq";
export var section = "textWithImagesInline1-module--section--FimfF";
export var spanImg = "textWithImagesInline1-module--spanImg--6AjsX";
export var spanWW = "textWithImagesInline1-module--spanWW--H4okY";
export var spanWrapper = "textWithImagesInline1-module--spanWrapper--ihwc4";
export var staticImg = "textWithImagesInline1-module--staticImg--GRTTx";
export var title = "textWithImagesInline1-module--title--9wi+c";