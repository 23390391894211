import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./sectionVideoAndText2.module.css"
import { StaticImage } from "gatsby-plugin-image"

const SectionVideoAndText2 = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={`row ${styles.mainRow}`}>
        <div className={`d-none d-xl-block ${styles.sep}`}></div>
        <div className={`col-xl-6 ${styles.left}`}>
          <div className={styles.videoContainer}>
            <StaticImage
              className={styles.imgBack}
              src='../images/information/Mansfield_027_bis.png'
              loading="eager"
              placeholder="blurred"
              layout="constrained"
              height={1000}
              quality={75}
              formats={["auto", "webp", "avif"]}
              alt={intl.formatMessage({ id: 'alts.street' })}
            />
          </div>
        </div>
        <div className={`col-xl-6 ${styles.right}`}>
          <div className={styles.rightColumn}>
            <div className={styles.top}>
              <h4>
                {intl.formatMessage({ id: 'information.videoTextRight2.top' })}
                <br />
                {intl.formatMessage({ id: 'information.videoTextRight2.bottom' })}
              </h4>
            </div>
            <div className={styles.mid}>
              <p>{intl.formatMessage({ id: 'information.videoTextRight2.m1' })}</p>
              <p>{intl.formatMessage({ id: 'information.videoTextRight2.m2' })}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionVideoAndText2