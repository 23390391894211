// extracted by mini-css-extract-plugin
export var imgBack = "sectionVideoAndText2-module--imgBack--RQIbq";
export var imgTop = "sectionVideoAndText2-module--imgTop--dGgQy";
export var left = "sectionVideoAndText2-module--left--nkyML";
export var mainRow = "sectionVideoAndText2-module--mainRow--BYA4a";
export var mid = "sectionVideoAndText2-module--mid--cHI2c";
export var right = "sectionVideoAndText2-module--right--DQTcO";
export var rightColumn = "sectionVideoAndText2-module--rightColumn--Ynoe3";
export var section = "sectionVideoAndText2-module--section--sj7Wd";
export var sep = "sectionVideoAndText2-module--sep--wsDFK";
export var top = "sectionVideoAndText2-module--top--FCSeb";
export var videoContainer = "sectionVideoAndText2-module--videoContainer--9JsWx";