import * as React from "react"

import * as styles from "./Information.module.css"
import Header from "../header"
import Footer from "../footer"

import { useIntl } from "gatsby-plugin-intl-v4"
import ArrowDownSVG from '../../assets/svg/double-caret-down.svg'

import { StaticImage } from "gatsby-plugin-image"

import ReactFullpage from "@fullpage/react-fullpage";

import TextWithImagesInline1 from "../textWithImagesInline1"
import TextWithImagesInline2 from "../textWithImagesInline2"
import InformationSVG from '../../assets/svg/txt_information.svg'
import AProposSVG from '../../assets/svg/txt_a_propos.svg'
import SectionVideoAndText from "../sectionVideoAndText"
import SectionTextAndVideo from "../sectionTextAndVideo"
import MapContainer from "../mapContainer"
import CentralVideoForInformation from "../centralVideoForInformation"
import SectionVideoAndText2 from "../sectionVideoAndText2"

const Information = () => {

    const intl = useIntl();

    return (

        <div className={`pageContainer`}>
            <Header hidePlaceholder={true} />
            <ReactFullpage
                licenseKey={'WNK68-CN51I-6KNC7-J1276-CSYRO'}
                sectionSelector={"section"}
                normalScrollElements={'#loader'}
                afterLoad={function (origin, destination, direction) {

                    setTimeout(() => {
                        if (destination.item.classList.contains("footerSection")) {
                            destination.item.classList.remove("fp-auto-height");
                        }
                    }, 250)

                }}
                onLeave={function (origin, destination, direction) {

                    if (origin.item.classList.contains("footerSection")) {
                        origin.item.classList.add("fp-auto-height");
                    }

                }}
                render={(comp) => (
                    <ReactFullpage.Wrapper>
                        <section className={`${styles.sectionFullPicture} active`}>
                            <InformationSVG width="100%" className={`${styles.titleAbsolute} ${intl.locale === 'en' ? "" : styles.hide}`} />
                            <AProposSVG width="100%" className={`${styles.titleAbsolute} ${intl.locale === 'fr' ? "" : styles.hide}`} />
                            <StaticImage
                                src='../../images/homepage/Mansfield_016_L.jpg'
                                loading="eager"
                                layout="fullWidth"
                                quality={85}
                                formats={["auto", "webp", "avif"]}
                                alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                                style={{
                                    height: "100%",
                                    maxWidth: "100%"
                                }}
                            />
                            <div className={styles.scrollDown}>
                                <p>{intl.formatMessage({ id: 'navigation.scrollDown' })}</p>
                                <ArrowDownSVG height={10} width={10} />
                            </div>
                        </section>
                        <TextWithImagesInline1 />
                        <SectionVideoAndText />
                        <TextWithImagesInline2 />
                        <SectionTextAndVideo />
                        <SectionVideoAndText2 />
                        <section><MapContainer /></section>
                        <CentralVideoForInformation />
                        <section className="footerSection fp-auto-height">
                            <Footer variant={2} hideBorder={true} />
                        </section>
                    </ReactFullpage.Wrapper>
                )}
            />
        </div>
    )
}

export default Information
