import * as React from "react"
import { Link, useIntl } from "gatsby-plugin-intl-v4"

import { StaticImage } from "gatsby-plugin-image"

import * as styles from "./textWithImagesInline1.module.css"
import ButtonV2 from "./buttonV2"


const TextWithImagesInline1 = () => {

  const intl = useIntl();

  return (
    <section className={styles.section}>
      <div className={styles.flexColumn}>
        <div className={styles.title}>
          <h3>{intl.formatMessage({ id: 'information.textWithImagesInline1.title' })}</h3>
        </div>
        <div className={styles.main}>
          <div className={styles.paragraph}>
            {dispText(
              intl.formatMessage({ id: 'information.textWithImagesInline1.text1' }),
              intl.formatMessage({ id: 'information.textWithImagesInline1.place1' }),
            )}
            <ImageSpan
              staticIm={
                <StaticImage
                  src='../images/information/Mansfield_033_L.jpg'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={120}
                  quality={75}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.spa' })}
                />
              }
            />
            {dispText(
              intl.formatMessage({ id: 'information.textWithImagesInline1.text2' }),
              intl.formatMessage({ id: 'information.textWithImagesInline1.place2' }),
            )}            <ImageSpan
              staticIm={
                <StaticImage
                  src='../images/information/Mansfield_034_L.jpg'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={120}
                  quality={75}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.wineBar' })}
                />
              }
            />
            {dispText(
              intl.formatMessage({ id: 'information.textWithImagesInline1.text3' }),
              intl.formatMessage({ id: 'information.textWithImagesInline1.place3' }),
            )}            <ImageSpan
              staticIm={
                <StaticImage
                  src='../images/information/Mansfield_035_L.jpg'
                  loading="eager"
                  placeholder="blurred"
                  layout="constrained"
                  height={120}
                  quality={75}
                  formats={["auto", "webp", "avif"]}
                  alt={intl.formatMessage({ id: 'alts.panoramicView' })}
                />
              }
            />
            {dispText(
              intl.formatMessage({ id: 'information.textWithImagesInline1.text4' }),
              intl.formatMessage({ id: 'information.textWithImagesInline1.place4' }),
            )}          </div>
        </div>
        <div className={styles.link}>
          <div>
            <Link to="/plans/">
              <ButtonV2
                content={intl.formatMessage({ id: 'homepage.textWithImagesInline.seePlan' })}
              />
            </Link>
          </div>
          <div className={styles.buttonLinkSep}>
            {intl.formatMessage({ id: 'homepage.textWithImagesInline.or' })}
          </div>
          <div>
            <Link to="/virtual-tours/">
              <ButtonV2
                content={intl.formatMessage({ id: 'homepage.textWithImagesInline.takeVirtualTour' })}
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

const dispText = (text, replace) => text.split(replace).map(
  (e, i, { length }) =>
    <>
      <span key={i}>{e}</span>
      {length - 1 === i ? <></> :
        <span className={styles.coloredSpan} key={i}>{replace}</span>}
    </>
)

const ImageSpan = ({ staticIm }) => {

  const [hover, setHover] = React.useState(false);

  return (
    <div className={styles.spanWW}>
      <div
        className={styles.hoverZone}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        role='presentation'
      >
      </div>
      <div className={styles.spanWrapper}>
        <div className={`${styles.spanImg} ${hover ? styles.hovered : ""}`}>
          {'('}
          {staticIm}
          {')'}
        </div>
      </div>
    </div>
  )
}

export default TextWithImagesInline1